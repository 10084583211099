<script>
import switchInput from '../../../components/switch-input.vue';
import { email } from "vuelidate/lib/validators";
import {
    FETCH_MERCHANT_ENTITY_DETAILS,
    UPDATE_MERCHANT_ENTITY_DETAILS
} from "@/state/actions.type";

export default {
  components: { switchInput },
  data() {
    return {
        toggleItems: {
            send_refund_email_notification: false,
            send_payment_email_notification: false,
            send_payout_email_notification: false,
        },
        payoutNotificationEmail: "",
        payment_notification_email: ""
    }
  },
  validations: {
    payoutNotificationEmail: { email },
    payment_notification_email: { email }
  },
  created() {
    this.$store.dispatch(`merchant/${FETCH_MERCHANT_ENTITY_DETAILS}`).then(() => {
        this.toggleItems = {
            send_refund_email_notification: this.entityDetails.send_refund_email_notification,
            send_payment_email_notification: this.entityDetails.send_payment_email_notification,
            send_payout_email_notification: this.entityDetails.send_payout_email_notification
        };
        this.payment_notification_email = this.entityDetails.payment_notification_email;
    });
  },

  computed: {
    entityDetails() {
      return this.$store.state.merchant.entityDetails;
    },
  },

  methods: {
    updateToggle(key) {
        if(this.entityDetails[key] !== this.toggleItems[key]) {
            const payload = {};
            payload[key] = this.toggleItems[key];

            this.$store.dispatch(`merchant/${UPDATE_MERCHANT_ENTITY_DETAILS}`, payload).then(() => {
              this.$notify({
                type: 'success',
                text: 'Value Updated Successfully',
                closeOnClick: true,
              });
            })
            .catch((error) => {
                // error
              console.log("error", error);
            });
        }
    },
    removeEmailFromPayoutNotification(email) {
        const emails = this.entityDetails.payout_notification_emails.filter(item => item !== email);
        this.updatePayoutEmails(emails);
    },
    updatePayoutEmails(emails) {
        this.$store.dispatch(`merchant/${UPDATE_MERCHANT_ENTITY_DETAILS}`, {
            payout_notification_emails: emails
        }).then(() => {
            this.$notify({
                type: 'success',
                text: 'Emails updated successfully',
                closeOnClick: true,
            });
            this.payoutNotificationEmail = "";
        })
        .catch((error) => {
            // error
            console.log("error", error);
        });
    },
    addEmailToPayoutNotification() {
        this.$v.payoutNotificationEmail.$touch();
        if(this.$v.payoutNotificationEmail.$anyError || !this.payoutNotificationEmail) {
            return false;
        }

        if(this.entityDetails.payout_notification_emails && this.entityDetails.payout_notification_emails.includes(this.payoutNotificationEmail)) {
            this.$notify({
                type: 'error',
                text: 'This email already exist',
                closeOnClick: true,
            });
            return;
        }

        let emails;
        if(this.entityDetails.payout_notification_emails) {
            emails = [this.payoutNotificationEmail, ...this.entityDetails.payout_notification_emails];
        } else {
            emails = [this.payoutNotificationEmail];
        }
        this.updatePayoutEmails(emails);
    },
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    changePaymentNotificationEmail() {
        this.$v.payment_notification_email.$touch();
        if(this.$v.payment_notification_email.$anyError || this.payment_notification_email == this.entityDetails.payment_notification_email) {
            return false;
        }

        this.$store.dispatch(`merchant/${UPDATE_MERCHANT_ENTITY_DETAILS}`, {
            payment_notification_email: this.payment_notification_email
        }).then((response) => {
            this.$notify({
                type: 'success',
                text: 'Payment receive notification email spdated successfully',
                closeOnClick: true,
            });
            this.payment_notification_email = response.payment_notification_email;
        })
        .catch((error) => {
            // error
            console.log("error", error);
        });
    }
  }
};
</script>

<template>
  <div>
    <div class="main-section">
      <div class="section-header">
        <p class="section-title">General Notifications</p>
        <p class="section-text">Set up notifications for your team for key events.</p>
      </div>
    </div>
    <div class="mt-3">
        <div class="toggle-settings">
            <div class="d-flex" style="gap: 15px;">
                <switch-input v-model="toggleItems.send_refund_email_notification" @input="updateToggle('send_refund_email_notification')" :value="toggleItems.send_refund_email_notification" />
                <div class="flex-fill">
                    <p class="mb-0">
                        Send Refund Email Notification
                    </p>
                    <p class="section-text font-14px">
                        Get notified whenever a refund is successful.
                    </p>
                </div>
            </div>
        </div>
        <div class="toggle-settings">
            <div class="d-flex" style="gap: 15px;">
                <switch-input v-model="toggleItems.send_payment_email_notification" @input="updateToggle('send_payment_email_notification')" :value="toggleItems.send_payment_email_notification" />
                <div>
                    <p class="mb-0">
                        Receive Email Notification for Payments
                    </p>
                    <p class="section-text font-14px">
                        Get notified whenever a transaction is successful.
                    </p>
                    <div v-if="toggleItems.send_payment_email_notification">
                        <b-form-input
                            id="example-input-1"
                            name="example-input-1"
                            v-model="payment_notification_email"
                            :state="validateState('payment_notification_email')"
                            aria-describedby="input-1-live-feedback"
                            @keypress.enter="changePaymentNotificationEmail"
                            class="email-list"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-1-live-feedback" >Email is invalid.</b-form-invalid-feedback>
                    </div>
                </div>
            </div>
        </div>
        <div class="toggle-settings">
            <div class="d-flex" style="gap: 15px;">
                <switch-input v-model="toggleItems.send_payout_email_notification" @input="updateToggle('send_payout_email_notification')" :value="toggleItems.send_payout_email_notification" />
                <div class="flex-fill">
                    <p class="mb-0">
                        Send Payout Email Notification
                    </p>
                    <p class="section-text font-14px">
                        Get notified whenever a payout is successful.
                    </p>
                    <div v-if="toggleItems.send_payout_email_notification">
                        <div class="email-list" :class="{'invalid': $v.payoutNotificationEmail.$anyError}">
                            <b-form-input
                                v-model="payoutNotificationEmail"
                                type="email"
                                placeholder="Add Email Here..."
                                class="border-0 m-2 w-75"
                                @keypress.enter="addEmailToPayoutNotification"
                            ></b-form-input>
                            <div class="d-flex flex-wrap">
                                <div class="email-text" v-for="(item, index) in entityDetails.payout_notification_emails" :key="index">
                                    {{ item }}
                                    <span class="ml-2 cursor-pointer" @click="removeEmailFromPayoutNotification(item)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                            <g clip-path="url(#clip0_1_70383)">
                                                <path d="M6.8775 11.1225L11.1225 6.8775M11.1225 11.1225L6.8775 6.8775M6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V6.75C16.5 3 15 1.5 11.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5Z" stroke="black" stroke-opacity="0.3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1_70383">
                                                <rect width="18" height="18" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="invalid-feedback" :class="{'d-block': $v.payoutNotificationEmail.$anyError}">Email is invalid.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.toggle-settings {
    border-top: 1px solid rgba(220, 218, 213, 0.30);
    padding: 15px 25px;
}
.email-list {
    border-radius: 15px;
    border: 1px solid #DCDAD5;
    min-height: 44px;
    margin-top: 10px;
}
.email-text {
    border-radius: var(--8, 8px);
    border: 1px solid rgba(220, 218, 213, 0.50);
    background: rgba(220, 218, 213, 0.30);
    padding: 11px 12px;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
    margin: 10px;
}
.invalid {
    border-color: #ff3d60;
}
</style>
