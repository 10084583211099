import { render, staticRenderFns } from "./webhook.vue?vue&type=template&id=96517644&scoped=true&"
import script from "./webhook.vue?vue&type=script&lang=js&"
export * from "./webhook.vue?vue&type=script&lang=js&"
import style0 from "./webhook.vue?vue&type=style&index=0&id=96517644&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96517644",
  null
  
)

export default component.exports