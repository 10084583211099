<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import Country from '../../../components/country.vue';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import {
  UPDATE_SUB_USER_DETAILS,
  FETCH_SUB_USER_DETAILS_BY_ID
} from "@/state/actions.type";
import cloneDeep from "lodash.clonedeep";

export default {
    components: {
        VuePhoneNumberInput,
        Country
    },
    data() {
        return {
            // languages: [
            //     {
            //         // flag: require("@/assets/images/flags/eng.png"),
            //         language: "en",
            //         title: "English"
            //     },
            //     {
            //         // flag: require("@/assets/images/flags/vietnam1.png"),
            //         language: "vn",
            //         title: "Vietnam"
            //     },
            //     {
            //         // flag: require("@/assets/images/flags/thailand.jpeg"),
            //         language: "th",
            //         title: "Thailand"
            //     },
            //     {
            //         // flag: require("@/assets/images/flags/korea.jpg"),
            //         language: "kr",
            //         title: "Korean"
            //     },
            //     {
            //         // flag: require("@/assets/images/flags/indonesia.png"),
            //         language: "id",
            //         title: "Indonesian"
            //     }
            // ],
            // current_language: null,
            form: {
                name: "",
                phone_number: "",
                country: ""
            },
            phoneNumber: null
        }
    },
    computed: {
        userDetails() {
            return this.$store.state.auth.currentUser;
        },
    },
    created() {
        // this.current_language = this.languages[0];
        this.resetProfileDetails();
        
    },
    methods: {
        countryChanged(value) {
            this.form.country = value;
        },
        // setLanguage(language) {
        //     this.current_language = language;
        // },
        saveProfile() {
            if(this.phoneNumber && this.phoneNumber.formattedNumber && !this.phoneNumber.isValid) {
                this.$notify({
                    type: "error",
                    text: "Phone number is Invalid",
                    closeOnClick: true,
                });
                return false;
            }

            const formData = cloneDeep(this.form);
            formData.phone_number = this.phoneNumber && this.phoneNumber.formattedNumber ? this.phoneNumber.formattedNumber : "";
            this.$store.dispatch(`accounts/${UPDATE_SUB_USER_DETAILS}`, {subUserID: this.userDetails.subUserUuid, subUserDetails: formData})
            .then(() => {
                this.$notify({
                    type: "success",
                    text: "Profile details saved successfully.",
                    closeOnClick: true,
                });
                this.$store.dispatch(`accounts/${FETCH_SUB_USER_DETAILS_BY_ID}`).then(this.resetProfileDetails);
            })
        },
        resetProfileDetails() {
            this.form = {
                name: this.userDetails.name,
                phone_number: "",
                country: this.userDetails.country
            };

            if(this.userDetails.phone_number && isValidPhoneNumber(this.userDetails.phone_number)) {
                const phoneNumber = parsePhoneNumber(this.userDetails.phone_number);
    
                this.phoneNumber = {
                    "countryCode": phoneNumber.country,
                    "isValid": phoneNumber.isValid(),
                    "phoneNumber": phoneNumber.nationalNumber,
                    "countryCallingCode": phoneNumber.countryCallingCode,
                    "formattedNumber": phoneNumber.number,
                    "nationalNumber": phoneNumber.nationalNumber
                }

                this.form.phone_number = phoneNumber.nationalNumber;
            }
        },
        detailsUpdated() {
            return (
                this.userDetails.name !== this.form.name ||
                (this.phoneNumber && (this.userDetails.phone_number !== this.phoneNumber.formattedNumber)) ||
                this.userDetails.country !== this.form.country
            );
        },
        updatePhoneNumber(e) {
            this.phoneNumber = e;
            this.form.phone_number = e.phoneNumber;
        },
    }
}
</script>

<template>
    <div class="d-flex" style="gap: 25px;">
        <div class="main-section">
            <div class="section-header">
                <p class="section-title">Personal Information</p>
                <p class="section-text">Update your profile details here.</p>
            </div>

            <form class="profile-form">
                <div class="row">
                    <b-form-group
                        id="field-first-name"
                        label="Name"
                        label-for="first-name"
                        valid-feedback="Thank you!"
                        class="col-md-12"
                        >
                        <b-form-input id="first-name" v-model="form.name" trim></b-form-input>
                    </b-form-group>

                    <!-- <b-form-group
                        id="field-last-name"
                        label="Last Name"
                        label-for="last-name"
                        valid-feedback="Thank you!"
                        class="col-md-6"
                        >
                        <b-form-input id="last-name" trim></b-form-input>
                    </b-form-group> -->
                </div>

                <b-form-group
                    id="field-email"
                    label="Email"
                    label-for="email"
                >
                    <b-input-group>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" stroke="black" stroke-opacity="0.7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9" stroke="black" stroke-opacity="0.7" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <input id="email" type="email" :value="this.userDetails.email_address" class="flex-fill" readonly disabled />
                        <div class="email-append" v-if="this.userDetails.is_email_verified">
                            Verified
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#219653" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M7.75 11.9999L10.58 14.8299L16.25 9.16992" stroke="#219653" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </b-input-group>
                </b-form-group>

                <!-- <b-form-group
                    id="field-about"
                    label="About"
                    label-for="about"
                    valid-feedback="Thank you!"
                >
                    <b-form-textarea
                        id="about"
                        placeholder="Write about you here..."
                        rows="5"
                        max-rows="6"
                    ></b-form-textarea>
                </b-form-group> -->

                <b-form-group
                    id="field-contact-number"
                    label="Contact Number"
                    label-for="contact-number"
                    valid-feedback="Thank you!"
                >
                    <VuePhoneNumberInput
                        :value="form.phone_number"
                        @update="updatePhoneNumber($event)"
                        :default-country-code="phoneNumber && phoneNumber.countryCode ? phoneNumber.countryCode : userDetails.country"
                        :preferred-countries="['IN', 'ID', 'MY', 'PH', 'SG', 'KR', 'TH', 'VN']"
                    />
                </b-form-group>

                <div class="row">
                    <b-form-group
                        id="field-country"
                        label="Country"
                        label-for="country"
                        valid-feedback="Thank you!"
                        class="col-md-12"
                    >
                        <Country :country="form.country" @country-changed="countryChanged" :disabled="true" />
                    </b-form-group>

                    <!-- <b-form-group
                        id="field-language"
                        label="Language"
                        label-for="language"
                        valid-feedback="Thank you!"
                        class="col-md-6"
                    >
                        <b-dropdown variant="white" dropup menu-class="w-100" class="w-100">
                            <template v-slot:button-content>
                                {{ current_language.language.toUpperCase() }}
                                <img src="@/assets/images/down.svg" alt="down" height="18" width="18" />
                            </template>

                            <b-dropdown-item
                                v-for="(option, i) in languages"
                                :key="`Lang${i}`"
                                @click="setLanguage(option)"
                                :link-class="{'active': option.language === current_language.language, 'd-flex flex-wrap align-items-center': true}"
                            >
                                {{ option.title }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-form-group> -->
                </div>

                <div v-if="detailsUpdated()" style="padding-top: 25px; gap: 12px; border-top: 1px solid rgba(220, 218, 213, 0.50);" class="d-flex justify-content-end">
                    <b-btn variant="primary" @click="resetProfileDetails" class="bg-white text-dark cancel">Cancel</b-btn>
                    <b-btn @click="saveProfile" class="save">Save</b-btn>
                </div>
            </form>
        </div>

        <!-- <div class="profile-section">
            <p class="section-title">Profile Picture</p>
            <p class="section-text">Change your profile picture here.</p>

            <div style="margin: 40px 33px 57px;">
                <svg xmlns="http://www.w3.org/2000/svg" width="221" height="221" viewBox="0 0 221 221" fill="none">
                    <circle cx="110.5" cy="110.5" r="110.5" fill="#DCDAD5"/>
                </svg>
            </div>

            <b-btn variant="primary" class="w-100 h-60px mb-25px">Change Photo</b-btn>
            <b-btn variant="white" class="w-100 h-60px border-black">Remove</b-btn>
        </div> -->
    </div>
</template>

<style lang="scss" scoped>
.main-section {
    width: 704px;
}
.profile-section {
    border-radius: 15px;
    border: 1px solid rgba(220, 218, 213, 0.50);
    background: rgba(255, 255, 255, 0.30);
    width: 337px;
    height: 570px;
    box-shadow: 0px 4px 12px 1px rgba(220, 218, 213, 0.30);
    padding: 25px;
}
.profile-form {
    padding: 25px;
}
.form-control {
    padding: 12px 16px;
    height: 44px;
    border-radius: 12px;
    border: 1px solid #DCDAD5;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    font-size: 14px;
}
.input-group {
    padding: 12px 16px;
    border-radius: 12px;
    border: 1px solid #DCDAD5;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    font-size: 14px;
    * {
        height: 24px;
    }
}
::v-deep .form-group > label {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: 20px;
    margin-bottom: 15px !important;
}
.input-group {
    gap: 8px;
    background: rgba(220, 218, 213, 0.30);
}
.form-group {
    margin-bottom: 25px;
}
input[type=email] {
    padding-left: 15px;
    padding-right: 15px;
    font-weight: 350;
}
input:read-only {
    background: transparent;
    border: none;
}
.email-append {
    color: #219653;
    font-size: 16px;
    font-style: normal;
    font-weight: 325;
    line-height: 24px;
}
::placeholder {
    color: rgba(0, 0, 0, 0.50);
    font-size: 16px;
    font-style: normal;
    font-weight: 325;
    line-height: 24px;
}
::v-deep .dropdown-toggle {
    display: flex;
    height: 44px;
    padding: 12px 16px;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;
    border: 1px solid #DCDAD5;
    font-size: 14px;
}
::v-deep .dropdown-menu {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.30);
    max-height: 50vh;
    overflow-y: scroll;

    a {
        height: 60px;
        font-size: 14px;
        .active {
            border-right: 5px solid #FC6B2D;
            background: rgba(220, 218, 213, 0.30);
        }
    }
}
</style>