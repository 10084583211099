import { render, staticRenderFns } from "./dcc-settings.vue?vue&type=template&id=14ad2aea&scoped=true&"
import script from "./dcc-settings.vue?vue&type=script&lang=js&"
export * from "./dcc-settings.vue?vue&type=script&lang=js&"
import style0 from "./dcc-settings.vue?vue&type=style&index=0&id=14ad2aea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14ad2aea",
  null
  
)

export default component.exports