export const rolesPermissionMapping = {
  "admin": [
    "create-payouts",
    "verify-payouts",
    "approve-payouts",
    "view-payouts",
    "verify-payouts",
    "create-virtual-accounts",
    "view-virtual-accounts",
    "create-beneficiaries",
    "view-beneficiaries",
    "create-payment-links",
    "view-payment-links",
    "view-transactions",
    "view-transaction-details",
    "initiate-transaction-refunds",
    "view-refunds-status",
    "view-dashboard",
    "create-webhooks",
    "view-webhooks",
    "config-payment-channel",
    "config-payout-channel",
    "invite-sub-users",
    "deactivate-sub-users",
    "edit-permissions-sub-users",
    "view-chaipay-docs",
    "view-chaipay-key",
    "view-chaipay-secret-key",
    "edit-general-details"
  ],
  "payment-links-user": [
    "create-payment-links",
    "view-payment-links",
    "view-chaipay-docs",
    "view-dashboard"
  ],
  "payouts-admin": [
    "create-payouts",
    "verify-payouts",
    "approve-payouts",
    "verify-payouts",
    "view-payouts",
    "create-virtual-accounts",
    "view-virtual-accounts",
    "create-beneficiaries",
    "view-beneficiaries",
    "view-chaipay-docs",
    "create-webhooks",
    "view-webhooks",
    "config-payout-channel"
  ],
  "payouts-user": [
    "create-payouts",
    "verify-payouts",
    "view-payouts",
    "view-virtual-accounts",
    "create-beneficiaries",
    "view-beneficiaries",
    "view-chaipay-docs"
  ],
  "payouts-verify-user": [
    "create-payouts",
    "verify-payouts",
    "view-payouts",
    "view-virtual-accounts",
    "create-beneficiaries",
    "view-beneficiaries",
    "view-chaipay-docs"
  ],
  "payouts-approve-user": [
    "create-payouts",
    "approve-payouts",
    "view-payouts",
    "view-virtual-accounts",
    "create-beneficiaries",
    "view-beneficiaries",
    "view-chaipay-docs"
  ],
  "payout-channel-configurator": [
    "config-payout-channel"
  ],
  "payment-channel-configurator": [
    "config-payment-channel"
  ],
  "payment-refund-user": [
    "initiate-transaction-refunds",
    "view-refunds-status",
    "view-chaipay-docs",
    "view-dashboard"
  ],
  "payment-user": [
    "view-chaipay-docs",
    "view-dashboard",
    "view-transactions",
    "view-transaction-details"
  ],
  "payment-admin": [
    "view-chaipay-docs",
    "view-dashboard",
    "view-transactions",
    "view-transaction-details",
    "create-webhooks",
    "view-webhooks",
    "config-payment-channel",
    "initiate-transaction-refunds",
    "view-refunds-status"
  ],
  "payment-pages-user": [
    "create-payment-links",
    "view-payment-links",
    "view-chaipay-docs",
    "view-transactions",
    "view-transaction-details",
    "create-payment-pages"
  ],
  "sub-admin": [
    "create-payouts",
    "verify-payouts",
    "approve-payouts",
    "view-payouts",
    "verify-payouts",
    "create-virtual-accounts",
    "view-virtual-accounts",
    "create-beneficiaries",
    "view-beneficiaries",
    "create-payment-links",
    "view-payment-links",
    "view-transactions",
    "view-transaction-details",
    "initiate-transaction-refunds",
    "view-refunds-status",
    "view-dashboard",
    "create-webhooks",
    "view-webhooks",
    "config-payment-channel",
    "config-payout-channel",
    "invite-sub-users",
    "deactivate-sub-users",
    "edit-permissions-sub-users",
    "view-chaipay-docs",
    "view-chaipay-key",
    "view-chaipay-secret-key",
    "edit-general-details"
  ],
  "invoice-user": [
    "create-invoices",
    "view-invoices",
    "view-payment-links",
    "view-chaipay-docs",
    "view-transactions",
    "view-transaction-details"
  ],
  "recon-user": [
    "view-chaipay-docs",
    "view-recons",
    "view-recon-details",
    "view-recon-dashboard"
  ],
  "recon-admin": [
    "view-chaipay-docs",
    "view-recons",
    "view-recon-dashboard",
    "view-recon-details",
    "create-recons",
    "config-recon"
  ],
  "recon-configurator": [
    "config-recon"
  ]
}