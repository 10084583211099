<script>
import moment from 'moment';
import switchInput from '../../../components/switch-input.vue';
import cloneDeep from 'lodash.clonedeep';
import {
  required,
  url
} from "vuelidate/lib/validators";
import {
  FETCH_WEBHOOKS,
  UPDATE_WEBHOOK,
  UPDATE_WEBHOOK_BY_FORM,
  ADD_WEBHOOK
} from "@/state/actions.type";

export default {
  components: { switchInput },
  data() {
    return {
      webhookModal: false,
      webhookDetails: {
        name: "",
        url: "",
        type: "",
      },
      webhookDetailsPrev: {
        name: "",
        url: "",
        type: "",
      },
      isEdit: false,
      fields: [
        { key: "created_at", sortable: true, label: "views.integration.api-settings.webhooks.table.created_at" },
        { key: "name", sortable: true, label: "views.integration.api-settings.webhooks.table.name" },
        { key: "url", sortable: true, label: "views.integration.api-settings.webhooks.table.url" },
        { key: "type", sortable: true, label: "views.integration.api-settings.webhooks.table.type" },
        { key: "enabled", sortable: false, label: "views.integration.api-settings.webhooks.table.enabled" },
        { key: "options", label: "", stickyColumn: true },
      ],
      webhookOptions: {
        'payment_status': 'views.integration.api-settings.webhooks.status.payment',
        'payout_status': 'views.integration.api-settings.webhooks.status.payout',
        'refund_status': 'views.integration.api-settings.webhooks.status.refund',
        'onboarding_status': 'views.integration.api-settings.webhooks.status.onboarding',
        'payment_link_status': 'views.integration.api-settings.webhooks.status.payment_link',
        'subscription_status': 'Subscription Status'
      }
    }
  },

  filters: {
    date(value) {
      return moment(value).format('MMMM DD YYYY, h:mm:ss a');
    }
  },

  validations: {
    webhookDetails: {
      name: { required },
      type: { required },
      url: { required, url }
    },
  },

  created() {
    this.$store.dispatch(`webhook/${FETCH_WEBHOOKS}`);
  },

  computed: {
    webhookData() {
      return this.$store.state.webhook.urls;
    },
    userRoles() {
      return this.$store.state.auth.currentUser.roles;
    },
  },

  watch: {
    userRoles() {
      this.webhookOptions = {};
      if(this.userRoles.includes('payment-admin') || this.userRoles.includes('admin') || this.userRoles.includes('sub-admin')) {
        this.webhookOptions.payment_status = 'views.integration.api-settings.webhooks.status.payment';
        this.webhookOptions.onboarding_status = 'views.integration.api-settings.webhooks.status.onboarding';
        this.webhookOptions.payment_link_status = 'views.integration.api-settings.webhooks.status.payment_link';
        this.webhookOptions.subscription_status = 'Subscription Status';
      }
      if(this.userRoles.includes('payouts-admin') || this.userRoles.includes('admin') || this.userRoles.includes('sub-admin')) {
        this.webhookOptions.payout_status = 'views.integration.api-settings.webhooks.status.payout';
      }
      if(this.userRoles.includes('payment-refund-user') || this.userRoles.includes('payouts-admin') || this.userRoles.includes('admin') || this.userRoles.includes('sub-admin')) {
        this.webhookOptions.refund_status = 'views.integration.api-settings.webhooks.status.refund';
      }
    }
  },

  methods: {
    openEditWebhookModal(item) {
      this.webhookDetails = cloneDeep(item);
      this.webhookDetailsPrev = cloneDeep(item);
      this.webhookModal = true;
      this.isEdit = true;
    },
    hideWebhookModal() {
      this.webhookModal = false;
      this.webhookDetails = {
        name: "",
        url: "",
        type: "",
      };
      this.webhookDetailsPrev = {
        name: "",
        url: "",
        type: "",
      };
      this.isEdit = false;
      this.$v.$reset();
    },
    toggleSwitch(value, item) {
      if (value != item.enabled) {
        const webhookData = {
          created_at: item.created_at,
          enabled: value,
          name: item.name,
          url: item.url,
          uuid: item.uuid,
        };

        webhookData.enabled = value;

        this.$store
          .dispatch(`webhook/${UPDATE_WEBHOOK}`, webhookData)
          .catch((error) => {
            console.log(error);
          });
      }
    },
    saveWebhook() {
      this.$v.webhookDetails.$touch();
      if (this.$v.webhookDetails.$invalid) {
        return;
      }
      let request;
      if(this.isEdit) {
        request = this.$store.dispatch(`webhook/${UPDATE_WEBHOOK_BY_FORM}`, this.webhookDetails);
      } else {
        request = this.$store.dispatch(`webhook/${ADD_WEBHOOK}`, this.webhookDetails);
      }

      request.then(() => {
        this.hideWebhookModal();
        this.$store.dispatch(`webhook/${FETCH_WEBHOOKS}`);
      })
      .catch((error) => {
        console.log(error);
        this.$notify({
          type: "error",
          text: error.message,
          closeOnClick: true,
        });
      });
    }
  }
};
</script>

<template>
  <div>
    <div class="main-section">
      <div class="section-header d-flex justify-content-between flex-wrap">
        <div>
          <p class="section-title">Webhooks</p>
          <p class="section-text">Set up webhooks for various events.</p>
        </div>
        <!-- v-if="!userRoles.includes('sub-admin')" -->
        <b-btn
          variant="primary"
          class="btn-padding"
          @click="() => webhookModal = !webhookModal"
        >
          <!-- @click="toggleEditSecreatKey" -->
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M6 12H18M12 18V6" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          Add Webhooks
          <!-- {{ $t("common.reset") }} -->
        </b-btn>
      </div>
    </div>

    <b-table
      class="table-custom"
      :items="webhookData"
      :fields="fields"
      responsive="sm"
      sticky-header="500px"
      show-empty
      :empty-text="$t('common.no-records')"
      hover
    >
      <template v-slot:head()="data">
        {{ $t(data.label) }}
      </template>
      <template v-slot:cell(created_at)="row">
        {{ row.value | date }}
      </template>
      <template v-slot:cell(type)="row">
        <span class="text-capitalize">{{ row.value.replace(/_/g, ' ') }}</span>
      </template>
      <template v-slot:cell(enabled)="{ item }">
        <switch-input :value="item.enabled" @input="toggleSwitch($event, item)" />
      </template>

      <template v-slot:cell(options)="row">
        <b-btn @click="openEditWebhookModal(row.item)" class="p-0" variant="white">
          <img src="@/assets/images/flags/edit.svg" alt="Edit" />
        </b-btn>
        <!-- <span v-if="row.value" class="btn btn-sm btn-danger" @click="deleteWebhookLink(row.value)">Delete</span> -->
      </template>
    </b-table>

    <b-modal
      id="webhook-modal"
      v-model="webhookModal"
      @hidden="hideWebhookModal"
      no-close-on-backdrop
      hide-footer
      hide-header
    >
      <div class="d-flex justify-content-between" style="padding-bottom: 25px;">
        <h5 class="font-20px text-black">{{ $t('views.integration.api-settings.webhooks.add-webhooks') }}</h5>
        <img @click="hideWebhookModal" class="modal-close" src="@/assets/images/close.svg" alt="close" />
      </div>

      <form>
        <div class="form-group">
          <label for="name">{{ $t('views.integration.api-settings.webhooks.name') }}<span class="text text-danger">*</span></label>
          <input
            id="name"
            v-model="webhookDetails.name"
            type="text"
            class="form-control"
            :placeholder="$t('views.integration.api-settings.webhooks.unique-name')"
            :class="{ 'is-invalid': $v.webhookDetails.name.$error }"
          />
          <div
            v-if="!$v.webhookDetails.name.required"
            class="invalid-feedback"
          >
            {{ $t('views.integration.api-settings.webhooks.validation.name') }}
          </div>
        </div>

        <div class="form-group">
          <label for="url">{{ $t('views.integration.api-settings.webhooks.url') }}<span class="text text-danger">*</span></label>
          <input
            id="url"
            v-model="webhookDetails.url"
            type="url"
            class="form-control"
            placeholder="https://mycompanysite.com"
            :class="{ 'is-invalid': $v.webhookDetails.url.$error }"
          />
          <div
            v-if="!$v.webhookDetails.url.required"
            class="invalid-feedback"
          >
            {{ $t('views.integration.api-settings.webhooks.validation.url') }}
          </div>
        </div>

        <div class="form-group">
          <label>{{ $t('views.integration.api-settings.webhooks.type') }}<span class="text text-danger">*</span></label>

          <b-dropdown variant="white" dropup menu-class="w-100" class="w-100">
            <template v-slot:button-content>
              <div class="d-flex align-items-center" style="gap: 15px;">
                <p class="mb-0">{{ $t(webhookOptions[webhookDetails.type]) }}</p>
              </div>
              <img class="mr-0" src="@/assets/images/down.svg" alt="down" height="18" width="18" />
            </template>

            <b-dropdown-item
              v-for="(option, key) in webhookOptions"
              :key="key"
              :disabled="webhookDetailsPrev.type == key"
              @click="() => webhookDetails.type = key"
              :link-class="{'active': key === webhookDetails.type, 'd-flex flex-wrap': true}"
            >
                <div class="my-auto">
                  <div class="d-flex align-items-center" style="gap: 15px;">
                    {{ $t(option) }}
                  </div>
                </div>
            </b-dropdown-item>
          </b-dropdown>
          <div
            v-if="$v.webhookDetails.type.$error"
            class="mt-1" style="font-size: 80%; color: #ff3d60;"
          >
            Type field is required
          </div>
        </div>

        <div class="text-right">
          <b-button variant="primary" class="bg-white text-dark cancel border-0" @click="hideWebhookModal">{{ $t('button.close') }}</b-button>
          <b-button variant="primary" class="save" @click="saveWebhook">{{ $t('button.save') }}</b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
::v-deep .table-custom .table > thead > tr {
  th:first-child, th:last-child {
    border-radius: 0;
  }
  .table-b-table-default {
    border-bottom: 1px solid #DCDAD5;
    background: #e9e7e3 !important;
    font-weight: 350;
    line-height: 20px;
  }
}
.input-group {
  padding: 17px 25px;
  border-radius: 8px;
  border: 1px solid #DCDAD5;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.form-group {
  margin-bottom: 25px;

  label {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 350;
    line-height: 20px;
    margin-bottom: 15px !important;
  }

  ::placeholder {
    color: rgba(0, 0, 0, 0.50);
    font-size: 16px;
    font-style: normal;
    font-weight: 325;
    line-height: 24px;
  }
}
.form-control {
  padding: 18px 25px;
  height: 60px;
  border-radius: 8px;
  border: 1px solid #DCDAD5;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
::v-deep .modal-body {
  padding: 35px;
}

.modal-close {
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background: rgba(220, 218, 213, 0.30);
  }
}

.modal-close {
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background: rgba(220, 218, 213, 0.30);
  }
}

::v-deep .dropdown-toggle {
  display: flex;
  height: 60px;
  padding: 18px 25px;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid #DCDAD5;
}
::v-deep .dropdown-menu {
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.30);
  max-height: 50vh;
  overflow-y: scroll;

  a {
    height: 60px;
    .active {
      border-right: 5px solid #FC6B2D;
      background: rgba(220, 218, 213, 0.30);
    }
  }
}
</style>
